import Api from '@/helpers/api/index.js'
import { removeEmptyParamsFromRequest } from '@/helpers/utils/'

const filesListToIdsList = (filesArr) => filesArr.map((item) => item.file_id)

export const createOrder = async (payload) => {
    const { data } = await Api.post('/api/order/checkout/express/create-or-update', {
        ...removeEmptyParamsFromRequest(payload),
        additional_files: filesListToIdsList(payload.additional_files)
    })
    return data.id
}
