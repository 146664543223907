export const validationRules = {
    preferred_writers: {
        requiredPreferred() {
            if (this.chk_writers === false) return true
            if (this.preferred_writers.length > 0) return true
            if (this.blocked_writers.length > 0) return true
            return false
        }
    },
    blocked_writers: {
        requiredBlocked() {
            if (this.chk_writers === false) return true
            if (this.blocked_writers.length > 0) return true
            if (this.preferred_writers.length > 0) return true
            return false
        }
    }
}
