import {
    requiredIf
} from 'vuelidate/lib/validators'

export const validationRules = {
    discount_code: {
        required: requiredIf(function () {
            return !!this.chk_discount
        }),
        discountExists(value) {
            value = value.trim()
            if (!value.length || !this.chk_discount) {
                return true
            }
            return this.validateDiscount({ discount: value })
                .then((resp) => resp)
        }
    }
}
