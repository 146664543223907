<template>
    <section>
        <div class="quote-sidebar__image" />
        <div class="quote-sidebar__text">
            {{ text }}
        </div>
    </section>
</template>

<script>
export default {
    name: 'QuoteSidebarInitialMessage',
    props: {
        text: {
            type: String,
            required: true
        }
    }
}
</script>
