<template>
    <div>
        <breadcrumbs
            :settings="settingsBreadcrumbs"
            back-btn-path="/order"
        />
        <RadiusLoader v-if="configLoading" />
        <div
            v-else
            class="express-order"
        >
            <div class="wrapper">
                <div
                    v-if="getterIsAuth"
                    class="express-order_row"
                >
                    <AccountTopBar />
                </div>

                <FastTrackForm :show-header="showOrderFormHeader" />
            </div>
        </div>
    </div>
</template>

<script>
import FastTrackForm from '@/components/order/FastTrackForm.vue';
import AccountTopBar from '@/components/AccountTopBar';
import RadiusLoader from '@/components/common/RadiusLoader.vue'
import Breadcrumbs from '@/components/Breadcrumbs';

import { createNamespacedHelpers, mapGetters } from 'vuex'
import {
    GET_PRICING
} from '@/store/modules/config/action-types'
import {
    ACTION_UPDATE_CLIENT_DATA
} from '@/store/modules/client/action-types'

const {
    mapActions: mapConfigActions
} = createNamespacedHelpers('config')
const {
    mapActions: mapClientActions
} = createNamespacedHelpers('client')
export default {
    name: 'OrderFastTrack',
    components: {
        AccountTopBar,
        FastTrackForm,
        RadiusLoader,
        Breadcrumbs
    },
    data() {
        return {
            configLoading: false
        }
    },
    computed: {
        ...mapGetters('client', [
            'getterIsAuth',
            'getterMainClientInfo'
        ]),
        showOrderFormHeader() {
            return (this.getterIsAuth && this.getterMainClientInfo.order_total === 0) || !this.getterIsAuth
        },
        settingsBreadcrumbs() {
            const { breadcrumbs } = this.$route.meta
            return {
                title: breadcrumbs.name,
                excerpt: breadcrumbs.excerpt,
                additionalClass: breadcrumbs.additionalClass
            }
        }
    },
    async created() {
        this.configLoading = true
        const requests = [this[GET_PRICING]()]
        if (this.getterIsAuth) {
            requests.push(this[ACTION_UPDATE_CLIENT_DATA]())
        }
        await Promise.all(requests)
        this.configLoading = false
    },
    methods: {
        ...mapConfigActions([
            GET_PRICING
        ]),
        ...mapClientActions([
            ACTION_UPDATE_CLIENT_DATA
        ])
    }
}
</script>

<style lang="scss">
    .express-order {
        &_row {
            margin: 0 0 50px;
            @media screen and (max-width: 480px) {
                padding: 0 15px;
            }
            &--flex {
                display: flex;
            }
            &:last-child {
                margin-bottom: 0;
            }
        }
        &_col-9{
            width: 75%;
            padding-right: 20px;
            @media all and (max-width: 992px) {
                width: 100%;
                padding-right: 0;
            }
        }
        &_col-3{
            padding-left: 20px;
            width: 25%;
            @media all and (max-width: 992px) {
                display: none;
            }
        }
        &_banner{
            position: sticky;
            top: 66px;
        }
        &_support-button{
            display: flex;
            justify-content: center;
            padding-top: 24px;
        }
    }
</style>
