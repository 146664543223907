<template>
    <section
        :id="$options.name"
        class="order-form-step assignment-details"
    >
        <header
            class="order-form-step__heading"
            :class="{'order-form-step__heading--closed': activeStep !== $options.name }"
        >
            <h1 class="order-form-step__heading">
                <span class="order-form-step__step-number">Step 2.</span> Assignment Details
            </h1>
            <CheckCircle
                v-if="isFormValid"
                class="order-form-step__confirm-icon"
            />
        </header>

        <section
            v-if="activeStep === $options.name"
            id="move_writer"
        >
            <div class=" order-form-step__column row row-subject">
                <form-select
                    v-model="$v.subject.$model"
                    :items="getterSubjects"
                    :value-select="$v.subject.$model"
                    :label="'Subject'"
                    tabindex="0"
                    name="subject"
                    :readonly="isMobile"
                    :errors="validationMessage($v.subject)"
                    @input.native="$v.subject.$touch();"
                    @blur.native="$v.subject.$touch();"
                />
            </div>

            <div class="order-form-step__column row">
                <form-switch
                    :id="'chk_title'"
                    v-model="chk_writer_select_topic"
                />
                <span class="order-form-step__switch-text">
                    Let the writer choose the title
                </span>
            </div>

            <div class="order-form-step__column row">
                <form-input
                    v-model="$v.title.$model"
                    legend="Title"
                    type="text"
                    name="title"
                    :info="'You can either provide a title or have the writer come up with one'"
                    :field.sync="title"
                    :is-valid="$v.title.$dirty && !$v.title.$anyError"
                    :errors="validationMessage($v.title)"
                    @input.native="$v.title.$touch();"
                    @blur.native="$v.title.$touch();"
                />
            </div>

            <div class="order-form-step__column row-textarea">
                <form-textarea
                    v-model="$v.details.$model"
                    legend="Detailed instructions"
                    placeholder="Tell us everything about your project. If you have instructions in a file, you can attach it later once the order is processed."
                    type="text"
                    name="details"
                    :field.sync="details"
                    :errors="validationMessage($v.details)"
                    @input.native="$v.details.$touch();"
                    @blur.native="$v.details.$touch();"
                />
                <span class="custom-tooltip">
                    <p>?</p>
                    <span>
                        Please be specific and detailed. Include all the requirements that you think are important. While you will be able to request a free revision/rewrite after completion,
                        we revise based on the original instructions, meaning that we will not be able to expand the paper free of charge,
                        if you ask for new things that werent originally requested (in that case you can easily purchase extra pages)
                    </span>
                </span>
            </div>

            <section class="section-files">
                <p class="section-files_label">
                    Additional Files
                </p>
                <div class="order-form-step__column row">
                    <FormFilesUploader
                        :is-uploading-in-progress="isUploadingInProgress"
                        :upload-progress="uploadProgressValue"
                        @uploadFiles="uploadFiles"
                    />
                </div>
                <div
                    v-if="files_add.length || files_uploaded.length"
                    class="order-form-step__column row"
                >
                    <OrderFormFileTable
                        :files="[...files_add, ...files_uploaded]"
                        @deleteFile="deleteFile"
                    />
                </div>
            </section>

            <section class="section-checkbox">
                <div class="order-form-step__column row specify">
                    <form-switch
                        :id="'chk_sources'"
                        v-model="chk_sources"
                    />
                    <span class="order-form-step__switch-text">
                        I need to specify the number of sources and citation style (otherwise, the writer will decide)
                    </span>
                </div>
            </section>

            <section>
                <div
                    v-if="chk_sources"
                    class="order-form-step-source"
                >
                    <div class="row space-between">
                        <div class="order-form-step__column order-form-step__column--half">
                            <form-select
                                v-model="$v.style.$model"
                                :items="getterStyle"
                                :value-select="style"
                                :label="'Citation style'"
                                tabindex="0"
                                name="style"
                                :readonly="isMobile"
                                :errors="validationMessage($v.style)"
                                @input.native="$v.style.$touch();"
                                @blur.native="$v.style.$touch();"
                            />
                            <span class="custom-tooltip">
                                <p>?</p>
                                <span>
                                    MLA (Modern Language Association) style is most commonly used to write papers and cite sources within the liberal arts and humanities.
                                    APA (American Psychological Association) is most commonly used to cite sources within the social sciences.
                                    Chicago/Turabian style places bibliographic citations at the bottom of a page or at the end of a paper.
                                    The Chicago and Turabian styles are most commonly thought of as note systems.
                                    Harvard referencing is the preferred style of the British Standards Institution.
                                    It is used mostly in the sciences and social sciences.
                                </span>
                            </span>
                        </div>

                        <div class="order-form-step__column order-form-step__column--half">
                            <form-input-type-number
                                v-model="$v.sources.$model"
                                label="Sources"
                                :value="sources"
                                :field.sync="sources"
                                :show_value="true"
                                tabindex="0"
                                :min_value="1"
                                :init_value="1"
                                :errors="validationMessage($v.sources)"
                                @reduce="sources--"
                                @increase="sources++"
                                @change.native="$v.sources.$touch()"
                            />
                            <span class="custom-tooltip">
                                <p>?</p>
                                <span>
                                    Usually we ask the client to specify the depth of research. That will be the number of external sources required for the writer to complete the assignement.
                                </span>
                            </span>
                        </div>
                    </div>
                </div>
            </section>

            <button-controls
                :settings="buttonSettings"
                :is-form-valid="isFormValid && !isCalculating"
                @next="prevalidate()"
                @previous="goToPrevious"
            />
        </section>
    </section>
</template>

<script>
import { deleteFile } from '@/services/files'
import { validationMixin } from 'vuelidate'
import { validationMessage } from 'vuelidate-messages'
import CheckCircle from 'mdi-vue/CheckCircle.vue'
import { mapGetters, createNamespacedHelpers } from 'vuex'
import { eventBus } from '@/helpers/event-bus'
import { mobileCheck } from '@/helpers/deviceChecker'

// components
import ButtonControls from '@/components/checkout/ButtonControls.vue'
import OrderFormFileTable from '@/components/order/OrderFormFileTable';

// validation
import formValidationMixin from '@/mixins/formValidationMixin'
import { validationRules } from '@/validation/order/RulesAssignmentDetails'
import { formMessages } from '@/validation/order/MessagesAssignmentDetails'

// vuex
import { mapOrderFormFields } from '@/store/modules/order'

import {
    ACTION_UPLOAD_FILES
} from '@/store/modules/order/action-types'

const {
    mapActions: mapOrderActions
} = createNamespacedHelpers('order')
export default {
    name: 'AssignmentDetails',
    components: {
        ButtonControls,
        CheckCircle,
        OrderFormFileTable
    },
    mixins: [
        validationMixin,
        formValidationMixin
    ],
    validations: {
        ...validationRules
    },
    props: {
        isCalculating: {
            type: Boolean,
            required: true
        },
        activeStep: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            isUploadingInProgress: false,
            uploadProgressValue: 0,
            isMobile: false
        }
    },
    computed: {
        ...mapOrderFormFields([
            'form_data.subject',
            'form_data.title',
            'form_data.chk_writer_select_topic',
            'form_data.details',
            'form_data.chk_sources',
            'form_data.sources',
            'form_data.style',
            'form_data.files_uploaded',
            'files_add'
        ]),
        ...mapGetters('order', [
            'getterValidationState'
        ]),
        ...mapGetters('config', [
            'getterSubjects',
            'getterStyle'
        ]),
        isFormValid() {
            return !this.$v.$invalid
        },
        buttonSettings() {
            return {
                previous: {
                    active: true,
                    loading: false
                },
                next: {
                    active: true,
                    loading: false
                }
            }
        }
    },
    watch: {
        chk_writer_select_topic(value) {
            if (value === true) {
                this.title = 'Writer\'s choice'
            } else {
                this.title = ''
            }
        },
        chk_sources(val) {
            if (val === false) {
                this.sources = 0;
                this.style = ''
            }
        }
    },
    created() {
        eventBus.$on('reset-form', () => {
            this.$v.$reset()
        })
    },
    mounted() {
        this.isMobile = mobileCheck()
    },
    methods: {
        ...mapOrderActions([
            ACTION_UPLOAD_FILES
        ]),
        validationMessage: validationMessage(formMessages),
        prevalidate() {
            this.validate()
        },
        goToPrevious() {
            this.$emit('setPreviousStep', this.$options.name)
        },
        submit() {
            this.$emit('setNextStep', this.$options.name)
        },
        async uploadFiles(filesArr) {
            this.files_add = [...this.files_add, ...Array.from(filesArr)]
            if (this.$route.params.orderid) {
                try {
                    this.isUploadingInProgress = true
                    await this[ACTION_UPLOAD_FILES]({
                        id: this.$route.params.orderid,
                        cb: (e) => {
                            this.uploadProgressValue = (Math.floor((e.loaded * 100) / e.total) - 1);
                        }
                    })
                    eventBus.$emit('showSnackBar', 'File was successfully uploaded', 'success');
                } catch (error) {
                    eventBus.$emit('showSnackBar', error, 'error');
                } finally {
                    this.isUploadingInProgress = false
                }
            }
        },
        async deleteFile(file) {
            try {
                if (this.$route.params.orderid) {
                    await deleteFile(file.file_id)
                    this.files_uploaded = this.files_uploaded.filter((item) => item.file_id !== file.file_id)
                    eventBus.$emit('showSnackBar', `File "${file.filename}" has been deleted`, 'success');
                } else {
                    this.files_add = this.files_add.filter((item) => item.lastModified !== file.lastModified)
                }
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error');
            }
        }
    }
}
</script>
