import {
    required,
    integer,
    minValue,
    maxValue,
    requiredIf
} from 'vuelidate/lib/validators'

import { Deadline } from '@/services/calculator/deadline.js'

export const validationRules = {
    type: {
        required
    },
    academiclevel: {
        required
    },
    due_at: {
        required,
        minimumTimeframe() {
            const instance = new Deadline(
                this.$store.state.config.pricing,
                this.$store.state.order.form_data
            )
            return instance.isTimeframeAllowedMinimum()
        },
        minimumTimeframeForProject() {
            const instance = new Deadline(
                this.$store.state.config.pricing,
                this.$store.state.order.form_data
            )
            return instance.isTimeframeAllowedForProject()
        }
    },
    pages: {
        required,
        integer,
        minValue: minValue(0),
        maxValue: maxValue(500),
        // do not put minValue here, it can be 0, if number of other services is bigger than 0
        minNumberOfPages() {
            if (this.pages === 0 && this.slides === 0 && this.charts === 0 && this.problems === 0) {
                return false
            }
            return true
        }
    },
    slides: {
        integer,
        maxValue: maxValue(100),
        minValue: minValue(0),
        required: requiredIf(function () {
            return this.chk_slides
        })
    },
    charts: {
        integer,
        maxValue: maxValue(100),
        minValue: minValue(0),
        required: requiredIf(function () {
            return this.chk_charts
        })
    },
    problems: {
        integer,
        maxValue: maxValue(100),
        minValue: minValue(0),
        required: requiredIf(function () {
            return this.chk_problems
        })
    },
    // discount_code: {
    //     required: requiredIf(function () {
    //         return !!this.chk_discount
    //     }),
    //     discountExists(value) {
    //         value = value.trim()
    //         if (!value.length || !this.chk_discount) {
    //             return true
    //         }
    //         return this.validateDiscount({ discount: value })
    //             .then((resp) => resp)
    //     }
    // },
    linked_to_order: {
        required: requiredIf(function () {
            return this.chk_linked
        })
    }
}
