export const formMessages = {
    required: () => 'Required',
    email: () => 'Must be a valid e-mail',
    integer: () => 'Must be an integer',
    minLength: ({ $params }) => `Must be at least ${$params.minLength.min} characters.`,
    maxLength: ({ $params }) => `Must be less than ${$params.maxLength.max} characters.`,
    minValue: ({ $params }) => `Must be at least ${$params.minValue.min}.`,
    maxValue: ({ $params }) => `Must be not greater than ${$params.maxValue.max}.`,
    minPagesNumber: ({ $params }) => `Must be at least ${$params.minPagesNumber.min}`,
    discountExists: () => 'The code is either expired or invalid',
    discountUsedInCart: () => 'Code is used in cart',
    minNumberOfPages: () => 'Can be 0, only if you order slides, charts or problems',
    minimumTimeframe: () => 'Timeframe too short',
    minimumTimeframeForProject: () => 'More hours needed for this project'
}
