import {
    required,
    integer,
    minValue,
    maxValue,
    requiredIf,
    minLength
} from 'vuelidate/lib/validators'
// import Api from '@/helpers/api/index.js'

export const validationRules = {
    // type: {
    //     required
    // },
    pages: {
        required,
        integer,
        minValue: minValue(1),
        maxValue: maxValue(500)
    },
    charts: {
        required: requiredIf(function () {
            return this.showCharts
        }),
        integer,
        minValue: minValue(1),
        maxValue: maxValue(500)
    },
    problems: {
        required: requiredIf(function () {
            return this.showProblems
        }),
        integer,
        minValue: minValue(1),
        maxValue: maxValue(500)
    },
    slides: {
        required: requiredIf(function () {
            return this.showSlides
        }),
        integer,
        minValue: minValue(1),
        maxValue: maxValue(500)
    },
    due_at: {
        required
    },
    title: {
        required
    },
    details: {
        required,
        minLength: minLength(20)
    },
    desired_price: {
        required,
        integer,
        minValue: minValue(1)
    }
}
