<template>
    <div class="row order-form-step__buttons">
        <custom-button
            v-if="settings.previous && settings.previous.active === true"
            class="btn-border"
            @on-btn-click="goToPrevious"
        >
            previous
        </custom-button>

        <custom-button
            v-if="settings.next.active === true"
            :loading="settings.next.loading"
            :class="isFormValid ? 'btn-base btn-main' : 'btn-base btn-disabled'"
            @on-btn-click="goToNext"
        >
            next
        </custom-button>
    </div>
</template>

<script>
export default {
    name: 'ButtonControls',
    props: {
        settings: {
            type: Object,
            required: true
        },
        isFormValid: {
            type: Boolean,
            required: true
        }
    },
    methods: {
        goToPrevious() {
            this.$emit('previous')
        },
        goToNext() {
            this.$emit('next')
        }
    }
}
</script>
