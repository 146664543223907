import {
    required,
    minLength,
    maxLength,
    requiredIf
} from 'vuelidate/lib/validators';

export const validationRules = {
    subject: {
        required
    },
    title: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(255)
    },
    details: {
        required,
        minLength: minLength(50),
        maxLength: maxLength(65535)
    },
    style: {
        required: requiredIf(function () {
            return this.chk_sources
        })
    },
    sources: {
        required: requiredIf(function () {
            return this.chk_sources && this.chk_sources_any
        })
    }
}
