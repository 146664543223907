import Api from '@/helpers/api/index.js'
import { SchemaOrder } from '@/models/SchemaOrder.js'
import { removeEmptyParamsFromRequest } from '@/helpers/utils/'

let defaultState = new SchemaOrder()

const postLog = async (payload) => {
    await Api.post('/api/order-log/create', removeEmptyParamsFromRequest({ ...payload }))
}

export const orderLog = async (form_data, orderid) => {
    await Object.keys(form_data).forEach(async (key) => {
        if (JSON.stringify(form_data[key]) !== JSON.stringify(defaultState[key])) {
            defaultState = new SchemaOrder(form_data)
            const payload = {
                key,
                value: JSON.stringify(form_data[key]),
                orderid: orderid ?? '',
                session_id: form_data.session_id ?? ''
            }
            await postLog(payload)
        }
    })
}

export const orderLogChangeSteps = async (payload) => {
    await postLog(payload)
}
